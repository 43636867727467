exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-notice-tsx": () => import("./../../../src/pages/privacy-notice.tsx" /* webpackChunkName: "component---src-pages-privacy-notice-tsx" */),
  "component---src-pages-regional-offices-tsx": () => import("./../../../src/pages/regional-offices.tsx" /* webpackChunkName: "component---src-pages-regional-offices-tsx" */),
  "component---src-pages-software-business-management-software-incident-manager-tsx": () => import("./../../../src/pages/software/business-management-software/incident-manager.tsx" /* webpackChunkName: "component---src-pages-software-business-management-software-incident-manager-tsx" */),
  "component---src-pages-software-business-management-software-index-tsx": () => import("./../../../src/pages/software/business-management-software/index.tsx" /* webpackChunkName: "component---src-pages-software-business-management-software-index-tsx" */),
  "component---src-pages-software-business-management-software-operations-software-tsx": () => import("./../../../src/pages/software/business-management-software/operations-software.tsx" /* webpackChunkName: "component---src-pages-software-business-management-software-operations-software-tsx" */),
  "component---src-pages-software-index-tsx": () => import("./../../../src/pages/software/index.tsx" /* webpackChunkName: "component---src-pages-software-index-tsx" */),
  "component---src-pages-software-operations-planning-software-index-tsx": () => import("./../../../src/pages/software/operations-planning-software/index.tsx" /* webpackChunkName: "component---src-pages-software-operations-planning-software-index-tsx" */),
  "component---src-pages-software-operations-planning-software-tracs-enterprise-tsx": () => import("./../../../src/pages/software/operations-planning-software/tracs-enterprise.tsx" /* webpackChunkName: "component---src-pages-software-operations-planning-software-tracs-enterprise-tsx" */),
  "component---src-pages-software-operations-planning-software-tracs-scheduling-software-tsx": () => import("./../../../src/pages/software/operations-planning-software/tracs-scheduling-software.tsx" /* webpackChunkName: "component---src-pages-software-operations-planning-software-tracs-scheduling-software-tsx" */),
  "component---src-pages-website-terms-tsx": () => import("./../../../src/pages/website-terms.tsx" /* webpackChunkName: "component---src-pages-website-terms-tsx" */),
  "component---src-templates-case-study-listing-tsx": () => import("./../../../src/templates/case-study-listing.tsx" /* webpackChunkName: "component---src-templates-case-study-listing-tsx" */),
  "component---src-templates-case-study-post-tsx": () => import("./../../../src/templates/case-study-post.tsx" /* webpackChunkName: "component---src-templates-case-study-post-tsx" */),
  "component---src-templates-news-listing-tsx": () => import("./../../../src/templates/news-listing.tsx" /* webpackChunkName: "component---src-templates-news-listing-tsx" */),
  "component---src-templates-news-post-tsx": () => import("./../../../src/templates/news-post.tsx" /* webpackChunkName: "component---src-templates-news-post-tsx" */)
}

